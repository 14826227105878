<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <a-form-model-item label="图片" prop="image" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.image" :src="form.image" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <p style="color:#ff0000">图片建议宽高比：301*380 </p>
        <span style="color:#ff0000">图片限制小于5M </span>
      </a-form-model-item>
      <!-- <a-form-model-item label="位置" prop="placeType" >
        <a-select v-model="form.placeType">
          <a-select-option :value="1">
            赛事
          </a-select-option>
          <a-select-option :value="2">
            刷手商城
          </a-select-option>
          <a-select-option :value="3">
            商品分类
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="投放時間" prop="date">
                <a-range-picker
                  v-model="form.date"
                  :placeholder="['开始時間', '结束時間']"
                  @change="onDateChange"
                  format="YYYY-MM-DD"></a-range-picker>
      </a-form-model-item>
      <a-form-model-item label="跳转類型" prop="skipType">
        <a-select v-model="form.skipType" @change="updateSkipType">
          <a-select-option :value="1">
            无
          </a-select-option>
          <a-select-option :value="2">
            站外链接
          </a-select-option>
          <a-select-option :value="3">
            站内链接
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="链接地址" prop="business" v-if="form.skipType == 2">
        <a-input v-model="form.business" placeholder="请输入链接地址" />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" v-if="form.skipType == 3">
        <editor v-model="form.remark" />
      </a-form-model-item>
      <!-- <a-form-model-item label="关联商品" prop="resourceId" v-if="form.skipType == 3">
        <a-select placeholder="请选择关联商品" optionFilterProp="children" show-search v-model="form.resourceId">
          <a-select-option v-for="(item,key) in goodsList" :key="item.name" :value="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="关联赛事" prop="resourceId" v-if="form.skipType == 4">
        <a-select placeholder="请选择关联赛事" optionFilterProp="children" show-search v-model="form.resourceId">
          <a-select-option v-for="(item,key) in competitionList" :key="item.title" :value="item.id">
            {{item.title}}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->
      <!-- <a-form-model-item label="排序" prop="sort" >
        <a-input v-model="form.sort" placeholder="请输入排序" />
      </a-form-model-item> -->

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSlideshow, addSlideshow, updateSlideshow } from '@/api/platform/slideshow'
import request from "@/utils/request";
import Editor from '@/components/Editor'
import UploadFileToOSS from "@/utils/upload-file-to-oss";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      goodsList: [],
      competitionList: [],
      // 表单参数
      form: {
        id: null,

        placeType: null,

        skipType: null,

        business: null,

        resourceId: null,

        sort: null,

        createTime: null,

        remark: null,

        image: null,

        beginTime: null,

        endTime: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        image:[
          { required: true, message: '图片不能为空', trigger: 'blur' },
        ],
        skipType:[
          { required: true, message: '类型不能为空', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        business: [
          { required: true, message: '链接地址不能为空', trigger: 'blur' }
        ],
        date: [
          { required: true, message: '投放时间不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  mounted () {
    //商品初始化
    this.goodsInit();
    //赛事初始化
    this.competitionInit();
  },
  methods: {
    onDateChange(value, dateString) {
      this.form.date = dateString;
      this.form.beginTime=dateString[0];
      this.form.endTime=dateString[1];
    },
    updateSkipType(e) {
      this.form.resourceId = null;
      this.form.business = null;
    },
    goodsInit(){
      request({
        url: '/platform/goods/list',
        method: 'get'
      }).then(response => {
        this.goodsList = response.data;
      })
    },
    competitionInit(){
      request({
        url: '/platform/competition/list',
        method: 'get'
      }).then(response => {
        this.competitionList = response.data;
      })
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.image = info.file.originFileObj.ossUrl;
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        placeType: null,
        skipType: null,
        business: null,
        resourceId: null,
        sort: null,
        createTime: null,
        remark: null,
        image: null
      }
    /*  this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSlideshow({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.form.date = [this.form.beginTime,this.form.endTime]
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSlideshow(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSlideshow(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
