import request from '@/utils/request'


// 查询轮播图列表
export function listSlideshow(query) {
  return request({
    url: '/platform/slideshow/list',
    method: 'get',
    params: query
  })
}

// 查询轮播图分页
export function pageSlideshow(query) {
  return request({
    url: '/platform/slideshow/page',
    method: 'get',
    params: query
  })
}

// 查询轮播图详细
export function getSlideshow(data) {
  return request({
    url: '/platform/slideshow/detail',
    method: 'get',
    params: data
  })
}

// 新增轮播图
export function addSlideshow(data) {
  return request({
    url: '/platform/slideshow/add',
    method: 'post',
    data: data
  })
}

// 修改轮播图
export function updateSlideshow(data) {
  return request({
    url: '/platform/slideshow/edit',
    method: 'post',
    data: data
  })
}

// 删除轮播图
export function delSlideshow(data) {
  return request({
    url: '/platform/slideshow/delete',
    method: 'post',
    data: data
  })
}
export function changeStatus(id) {
  return request({
    url: '/platform/slideshow/changeStatus?id=' + id,
    method: 'post'
  })
}
